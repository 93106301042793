<template>
  <div class="form-row">
    <div class="form-group col-md-12">
      <div class="customselect">
        <input
          type="text"
          v-model="input"
          @input="search"
          class="form-control form-control-sm input-form shadow-none"
          :placeholder="dataZap.placeholder + addPerzent(downloadPercentage)"
          :disabled="dataZap.loading"
        />
        <div v-if="visibleSearchData" class="customselect-result">
          <table class="customselect-table" v-if="searchdata.length">
            <thead>
              <tr>
                <th>наименование</th>
                <th>артикул</th>
                <th>код</th>
                <th>остаток</th>
                <th>цена</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in searchdata"
                :key="item.cod"
                @click="selectZap(item.cod, item.name, item.art)"
              >
                <td
                  class="search"
                  v-html="backLightFilter(item.name, input)"
                ></td>
                <td
                  class="search"
                  v-html="backLightFilter(item.art, input)"
                ></td>
                <td
                  class="search"
                  v-html="backLightFilter(item.cod, input)"
                ></td>
                <td class="text-center">{{ item.ostatok }}</td>
                <td class="text-center">{{ item.cena }}</td>
              </tr>
            </tbody>
          </table>
          <div v-else class="text-center">
            по запросу <span class="search-text">{{ input }}</span> ничего не
            найдено
          </div>
        </div>
        <div v-else />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectZapForRepair",
  props: ["dataZap", "downloadPercentage"],
  data: () => ({
    input: "",
    searchdata: null,
    visibleSearchData: false,
    cod: null
  }),
  methods: {
    search() {
      this.input = this.input.replace(/^\s*(.*)\s*$/, "$1");
      if (this.input.length > 2) {
        const str = this.input.split(" ");
        let result = this.dataZap.data;
        for (let i = 0; i < str.length; i++) {
          const reg = new RegExp(str[i], "gi");
          result = result.filter(
            item =>
              reg.test(item.name) || reg.test(item.art) || reg.test(item.cod)
          );
        }
        this.searchdata = result;
        this.visibleSearchData = true;
      } else {
        this.searchdata = null;
        this.visibleSearchData = false;
      }
    },
    backLightFilter(text, input) {
      const splitinput = input.split(" ");
      for (let i = 0; i < splitinput.length; i++) {
        text = text
          .toLowerCase()
          .replace(
            splitinput[i].toLowerCase(),
            `<span class="customselect-selected">${splitinput[i]}</span>`
          );
      }
      return text;
    },
    selectZap(cod, name, art) {
      this.visibleSearchData = false;
      this.input = "";
      this.cod = cod;
      this.$emit("addZapForRepair", { cod, name, art, pcs: 1 });
    },
    addPerzent(item) {
      if (item > 0) {
        return `${item}%`;
      }
      if (!item) {
        return "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
table {
  border: 1px solid lightgray;
  border-collapse: collapse;
}

td,
th {
  border: 1px solid lightgray;
  font-size: 0.7em;
  padding: 3px;
}
.customselect {
  position: relative;
  margin-top: 10px;
  &-result {
    position: absolute;
    top: 2em;
    z-index: 3;
    left: 0;
    width: 100%;
    max-height: 400px;
    overflow: auto;
    background: white;
    border: 1px solid lightgray;
  }
  &-table {
    width: 100%;
  }
  &-selected {
    background: yellow;
  }
}
tr:hover {
  cursor: pointer;
  background: lightgray;
}
.customselect-selected {
  background: yellow;
}
.search-text {
  color: red;
}
</style>
<style>
.customselect-selected {
  background: yellow;
}
</style>
